


































































































































































































































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import Logo from "@/components/Logo.vue";
import Banner from "@/components/Banner.vue";
import {CustomField, dateSort, Field, fieldSort, RegistrationDate, RegistrationForm} from "@/models/RegistrationForm";
import SpeakerBlock from "@/components/SpeakerBlock.vue";
import User from "@/components/User.vue";
import {
  CustomFieldWithValueString,
  DateWithCheck,
  FieldWithValueBoolean,
  FieldWithValueString,
  Salutation,
  UserData
} from "@/models/UserData";
import {Validations} from "vuelidate-property-decorators";
import {helpers, maxLength, required, requiredIf, sameAs} from "vuelidate/lib/validators";
import InputLine from "@/components/InputLine.vue";
import {Country, countryCodes, countryCodes as countries} from "@/models/CountryCodes";
import PhoneValidationService, {ValidatedPhoneNumber} from "@/service/PhoneValidationService";
import DateCard from "@/components/DateCard.vue";
import {Locale} from "date-fns";
import RegistrationService from "@/service/RegistrationService";
import {AxiosError} from "axios";
import {Page} from "@/store/form";
import {validateEmail, validateVvid} from "@/utils/validators";
import {fi} from "date-fns/locale";

@Component({
  computed: {
    fi() {
      return fi
    }
  },
  components: {DateCard, InputLine, User, SpeakerBlock, Banner, Logo}
})
export default class RegForm extends Vue {

  showValidation = false;
  allDatesSelected = false;

  countryCodes = countries;
  currentCountryCode = 'DE';
  currentPhone = "";
  dates: Array<DateWithCheck> = new Array<DateWithCheck>();
  fieldsString: Array<FieldWithValueString> = new Array<FieldWithValueString>();
  fieldsBoolean: Array<FieldWithValueBoolean> = new Array<FieldWithValueBoolean>();
  customFieldsString: Array<CustomFieldWithValueString> = new Array<CustomFieldWithValueString>();
  multiSelect = true;

  mounted(): void {
    this.init();
  }

  @Watch('form')
  onFormChanged(): void {
    this.init();
  }

  @Watch('userData')
  onUserDataChanged(): void {
    this.init();
  }

  init(): void {
    this.initFields();
    this.initPhone();
    this.initDates();
  }

  get currentCountry(): Country {
    return this.countryCodes.find(c => c.isoCode == this.currentCountryCode) || countryCodes[0]
  }

  initFields(): void {
    if (!this.form) return;

    this.fieldsString.splice(0, this.fieldsString.length);
    this.getFields(this.form.fields).forEach(field => this.fieldsString.push(field));

    this.customFieldsString.splice(0, this.customFieldsString.length);
    const fields = RegistrationService.sortCustomFields(this.form.customFields || []);
    this.getCustomFields(fields).forEach(field => this.customFieldsString.push(field));

    this.fieldsBoolean.splice(0, this.fieldsBoolean.length);
    this.getFieldsBoolean(this.form.fields).forEach(field => this.fieldsBoolean.push(field));


    if (this.userData.values) {
      this.userData.values.forEach(value => {
        const stringField = this.getStringField(value.fieldId);
        if (stringField) stringField.value = value.value || "";
        const booleanField = this.getBooleanField(value.fieldId);
        if (booleanField) booleanField.value = value.value === 'true';
      })
    }

    if (this.userData.customValues) {
      this.userData.customValues.forEach(value => {
        const stringField = this.getCustomField(value.fieldId);
        if (stringField) stringField.value = value.value || "";
      })
    }

    if (this.userData.dateIds) {
      this.setDates(this.userData.dateIds);
    }
  }

  getFields(fields: Array<Field> | undefined): Array<FieldWithValueString> {
    if (!fields) return [];
    return fields.filter(field => field.type === 'TEXT_LINE').sort(fieldSort).map(field => {
      return {field, value: ""};
    });
  }

  getCustomFields(fields: Array<CustomField> | undefined): Array<CustomFieldWithValueString> {
    if (!fields) return [];
    return fields.map(field => ({field, value: ""}));
  }

  getFieldsBoolean(fields: Array<Field> | undefined): Array<FieldWithValueBoolean> {
    if (!fields) return [];
    return fields
      .filter(field => field.type === 'CHECKBOX')
      .sort(fieldSort)
      .map(field => ({field, value: false}));
  }

  setDates(checkedDateIds: Array<number>): void {
    this.dates = this.sortedDates
      .map(date => ({date, check: checkedDateIds.find(id => date.id === id) !== undefined}));
  }

  getStringField(id: number): FieldWithValueString | undefined {
    return this.fieldsString.find(field => field.field.id === id);
  }

  getCustomField(id: number): CustomFieldWithValueString | undefined {
    return this.customFieldsString.find(field => field.field.id === id);
  }

  getBooleanField(id: number): FieldWithValueBoolean | undefined {
    return this.fieldsBoolean.find(field => field.field.id === id);
  }

  initPhone(): void {
    if (!this.form) return;

    this.currentCountryCode = this.form.branding?.countryISO || "DE";

    if (this.userData.phone) {
      // Split international phone number in country code and local number
      PhoneValidationService.validatePhoneNumber(this.userData.phone, "DE").then(validatedNumber => {
        if(validatedNumber.regionCode){
          this.currentCountryCode = validatedNumber.regionCode || this.currentCountryCode;
        }
        if (this.currentCountry) {
          if(validatedNumber.international){
            this.currentPhone = validatedNumber.international.slice(this.currentCountry.dialCode.length).trim();
          } else {
            this.currentPhone = '';
          }
        }
      })
    }
  }

  initDates(): void {
    if (!this.form) return;
    this.dates = this.sortedDates.map(date => ({date, check: this.userData.dateIds.includes(date.id)}));
    this.allDatesSelected = this.dates.filter(date => date.check).length === this.dates.length;

    // if only one active date -> select it
    if (!this.key) {
      const activeRegDates = this.form.regDates.filter(regDate => RegistrationService.getDateStatus(regDate) === "Active")
      if (activeRegDates.length === 1) {
        const date = this.dates.find((date: DateWithCheck) => date.date.id === activeRegDates[0].id)
        if (date) this.clickDate(date)
      }
    }

    const dateStates = this.dates.map(date => RegistrationService.getDateStatus(date.date));
    this.multiSelect = dateStates.filter(status => status === 'Active').length > 1;
  }

  get form(): RegistrationForm {
    return this.$store.getters.form;
  }

  get userData(): UserData {
    return this.$store.getters.userData;
  }

  get key(): string | undefined {
    return this.$store.getters.key;
  }

  get sortedDates(): Array<RegistrationDate> {
    if (this.form) {
      return this.form.regDates.sort(dateSort);
    }
    return [];
  }

  get salutations(): Array<string> {
    return [Salutation.MALE, Salutation.FEMALE, Salutation.DIVERS]
  }

  get locale(): Locale {
    return this.$store.getters.locale;
  }

  get dateState(): boolean | null {
    if (this.showValidation) {
      if (this.userData.dateIds.length === 0) return false;
    }
    return null
  }

  get footerEnabled(): boolean {
    return this.$store.getters.footerEnabled;
  }

  get footer(): string {
    return this.$store.getters.footer;
  }

  get privacyText(): string {
    return this.$store.getters.privacy;
  }

  clickDate(dateWithCheck: DateWithCheck): void {
    const status = RegistrationService.getDateStatus(dateWithCheck.date);
    if (status === "Past" || (status === "BookedOut" && !dateWithCheck.check)) return

    if (dateWithCheck.check) {
      dateWithCheck.date.countRegisteredUser -= 1;
    } else {
      dateWithCheck.date.countRegisteredUser += 1;
    }

    if (!this.form.allowMultipleDateSelection) this.dates.forEach(date => date.check = date !== dateWithCheck ? false : date.check)

    dateWithCheck.check = !dateWithCheck.check;

    this.userData.dateIds = this.dates.filter(date => date.check).map(date => date.date.id);
    this.allDatesSelected = this.userData.dateIds.length === this.dates.length;
  }

  @Validations()
  validations(): any {
    return {
      userData: {
        salutation: {required: requiredIf(() => this.form.salutationRequired)},
        firstname: {required, maxLength: maxLength(255)},
        lastname: {required, maxLength: maxLength(255)},
        addressStreet: {
          maxLength: maxLength(255),
          required: requiredIf(() => this.form.addressRequired)
        },
        addressCity: {
          maxLength: maxLength(255),
          required: requiredIf(() => this.form.addressRequired),
          validateCity: this.validateCity
        },
        company: {
          maxLength: maxLength(255),
          required: requiredIf(() => this.form.companyRequired)
        },
        email: {required, email: validateEmail, maxLength: maxLength(255)},
        partnerId: {
          maxLength: maxLength(255),
          required: requiredIf(() => this.form.partnerIdRequired)
        },
        vvId: {
          maxLength: maxLength(255),
          required: requiredIf(() => this.form.vvIdRequired),
          validateVvid: validateVvid,
          notExample: (value: string) => !(this.$t('form.vvIdHint') as string).includes(value) || value === ''
        },
        comment: {required: requiredIf(() => this.form.commentRequired)}
      },
      currentPhone: {
        required: requiredIf(() => this.form.phoneRequired),
        phoneFormat: this.phoneValidation(this.currentCountry.isoCode)
      },
      fieldsString: {
        $each: {
          value: {
            required: requiredIf((field: FieldWithValueString) => field.field.required)
          }
        }
      },
      customFieldsString: {
        $each: {
          value: {
            required: requiredIf((field: CustomFieldWithValueString) => field.field.required),
            regex: (value: string, vm: CustomFieldWithValueString) => {
              if (value != "" && (vm.field.regex && vm.field.regex != "")) {
                const regexPattern = new RegExp("^" + vm.field.regex + "$", vm.field.ignoreCase ? "i" : "");
                return regexPattern.test(value);
              }
              return true;
            },
            length: (value: string, vm: CustomFieldWithValueString) => {
              if (vm.field.maxLength != undefined && !(vm.field.maxLength <= 0)) {
                return value ? !(value.length > vm.field.maxLength) : true;
              }
              return true;
            }
          }
        }
      },
      fieldsBoolean: {
        $each: {
          value: {
            sameAs: sameAs((field: FieldWithValueBoolean) => field.field.required ? true : field.value) // Value must be true
          }
        }
      }
    };
  }

  // Custom validator as higher order function (https://vuelidate.js.org/#sub-extra-parameters)
  phoneValidation = (isoCode: string) => (value: string) => {
    if(value == ''){
      this.$store.commit('setPhone', '');
      return true;
    }
    return PhoneValidationService.validatePhoneNumber(value, isoCode)
        .then((validatedNumber: ValidatedPhoneNumber) => {
          // Store the international phone in store (will be later used in 'register' method)
          this.$store.commit('setPhone', validatedNumber.international);
          return validatedNumber.validNumber
        })
  }

  validateCity = helpers.regex('validateCity', /(\d|\w|\W)+ \w+/);

  label(name: string, required = true): string {
    return this.$t(`form.${name}`) + (required ? '*' : '')
  }

  register(update: boolean): void {
    if (!this.form) return;
    if (update && this.key === undefined) return;

    this.showValidation = true;
    if (this.$v.$invalid || this.dateState === false) {
      this.scrollToValidation(); // Scroll to the validation messages
    } else {
      this.setPage('loading');
      this.userData.values = [];
      this.fieldsString.forEach(field => this.userData.values.push({
        fieldId: field.field.id,
        value: field.value ? field.value : ''
      }));
      this.fieldsBoolean.forEach(field => this.userData.values.push({
        fieldId: field.field.id,
        value: field.value.toString()
      }));
      this.userData.customValues = [];
      this.customFieldsString.forEach(field => {
        this.userData.customValues.push({
          fieldId: field.field.id,
          value: (field.value || "").toString()
        })
      });
      this.userData.phone = this.$store.getters.phone;

      console.log("Data set")

      if (update) {
        if (this.key === undefined) return
        console.log("Sending")
        RegistrationService.updateUserData(this.form.id, this.key, this.userData)
            .then(() => this.setPage("updated"))
            .catch((e: AxiosError) => this.handleError(e))
            .finally(this.scrollToTop);
      } else {
        RegistrationService.sendUserData(this.userData, this.form.id)
            .then(userData => {
              this.$store.commit('setKey', userData.key);
              this.setPage("registered");
            })
            .catch((e: AxiosError) => this.handleError(e))
            .finally(this.scrollToTop)
      }
    }
  }

  private handleError(e: AxiosError) {
    if (e.response?.status === 400) {
      if (e.response.data.message.includes("Mail or phone")) {
        this.setPage("banned", 'form');
      } else {
        this.setPage("already_registered", 'form');
      }
    } else {
      this.setPage("error", 'form');
    }
  }

  deleteRegistration(): void {
    if (!this.key || !this.form) return
    if (confirm(this.$t("confirmDelete").toString())) {
      RegistrationService.deleteUserData(this.form.id, this.key)
          .then(() => this.setPage("deleted"))
          .catch(() => this.setPage("error")
          );
    }
  }

  toggleAllDates(): void {
    this.allDatesSelected = !this.allDatesSelected;

    this.dates.forEach(date => {
      if (RegistrationService.getDateStatus(date.date) !== "Active") return;
      date.check = this.allDatesSelected;
      this.userData.dateIds = this.dates.filter(date => date.check).map(date => date.date.id);
    })
  }

  scrollToTop(): void {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  scrollToValidation(): void {
    const validationElement = document.getElementById("validation");
    if (!validationElement) return;

    validationElement.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  setPage(page: Page, lastPage?: Page): void {
    this.$store.commit('setLastPage', lastPage || this.$store.getters.page);
    this.$store.commit('setPage', page);
  }

}
